import React from 'react';

const Sociallinks = () => {
    return (
        <div className='flex gap-4 my-8'>
            <a href="https://www.linkedin.com/in/ralphplacide-1/">
                <i className="lab la-linkedin-in text-4xl text-secondary-grey transition-all duration-300 ease-in-out bg-clip-text hover:text-transparent hover:bg-gradient-to-br hover:from-hot-pink hover:to-deep-orange hover:transform hover:-translate-y-1"></i>
            </a>
            <a href="https://github.com/ralphZero">
                <i className="lab la-github text-4xl text-secondary-grey transition-all duration-300 ease-in-out bg-clip-text hover:text-transparent hover:bg-gradient-to-br hover:from-hot-pink hover:to-deep-orange hover:transform hover:-translate-y-1"></i>
            </a>
            <a href="https://www.instagram.com/ralph.works/">
                <i className="lab la-instagram text-4xl text-secondary-grey transition-all duration-300 ease-in-out bg-clip-text hover:text-transparent hover:bg-gradient-to-br hover:from-hot-pink hover:to-deep-orange hover:transform hover:-translate-y-1"></i>
            </a>
            <a href="https://stackoverflow.com/users/13251130/tgi-rxlph">
                <i className="lab la-stack-overflow text-4xl text-secondary-grey transition-all duration-300 ease-in-out bg-clip-text hover:text-transparent hover:bg-gradient-to-br hover:from-hot-pink hover:to-deep-orange hover:transform hover:-translate-y-1"></i>
            </a>
        </div>
    );
}

export default Sociallinks;
