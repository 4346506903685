import React, { useEffect } from 'react';
import Contactform from '../components/Contact/ContactForm';
import Sociallinks from '../components/Contact/SocialLinks';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const Contact = () => {
    gsap.registerPlugin(ScrollTrigger);
    const year = new Date().getFullYear();

    useEffect(() => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.contact',
                start: '50% 100%',
                end: '60% 80%',
            }
        });
        tl.from('.contact__title', { opacity: 0, y: 70 });
        tl.from('.contact__subtitle', { opacity: 0, y: 70 });
        tl.addLabel('endTitle')
        let ftl = gsap.timeline();
        ftl.from('.form__email', { opacity: 0, y: 70 });
        ftl.from('.form__subject', { opacity: 0, y: 70 });
        ftl.from('.form__message', { opacity: 0, y: 70 });
        ftl.from('.form__button', { opacity: 0, y: 70 });
        tl.add(ftl, 'endTitle');
    }, [])

    return (
        <>
            <div className='contact p-5 flex mt-28 flex-col items-center'>
                <div className='contact__title page__title_center text-white text-3xl md:text-5xl font-semibold mt-12'>
                    get in touch
                </div>
                <div className='contact__subtitle text-center font-semibold text-primary-grey text-lg py-12 md:w-96 lg:w-5/12'>Whether you have a question or just want to say hi, I’ll try my best to get back to you!</div>
                <Contactform />
                <br />
                <Sociallinks />
            </div>
            <footer className='p-5 flex justify-center bg-black text-tertiary-grey'>
                { `© ${year} sebastienplacide.dev` }
            </footer>
        </>
    );
}

export default Contact;
