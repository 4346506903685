import React from 'react';

const Pagetitle = ({ text, id = '' }) => {
    return (
        <div id={id} className='page__title text-white text-3xl md:text-5xl font-semibold mt-12 self-start'>
            { text }
        </div>
    );
}

export default Pagetitle;
