import React, { useEffect, useState } from 'react';
import menu from '../../assets/menu.svg';
import close from '../../assets/close.svg';
import { gsap } from 'gsap';
import brand from '../../assets/signature.svg';

const Menutoggle = () => {
    const tl = gsap.timeline();
    let hypotenuse = Math.sqrt(Math.pow(window.screenX, 2) + Math.pow(window.screenY, 2)) + 50;

    useEffect(() => {
        const container = document.querySelector('.menu__container');
        window.addEventListener('resize', (e) => {
            hypotenuse = Math.sqrt(Math.pow(window.screenX, 2) + Math.pow(window.screenY, 2)) + 50;
        });
    }, []);
    
    const [isOpen, setIsOpen] = useState(false);

    const openMenu = () => {
        if(!isOpen) {
            tl.set('body', { overflow: 'hidden' });
            tl.set('.menu__icon', { attr: { src: close } });
            tl.to('.menu__container', { display: 'block' });
            tl.set('.menu__toggle', { backgroundColor: '#000000' }, 0.2);
            tl.to('.menu__container', { duration: 0.3,  scale: hypotenuse, onComplete: () => { setIsOpen(true); } }, 0.2);
            
            tl.to('.menu__content', { display: 'block', }, 0.5);
            tl.from('.menu__brand', { y: 10, opacity: 0 }, 0.6);
            tl.from('.menu__social', { x: -30, skewX: -5, opacity: 0 }, 0.7);
            tl.from('.menu__links', { x: 30, skewX: 5, opacity: 0 }, 0.7);
        }
    }

    const closeMenu = () => {
        if(isOpen) {
            tl.to('.menu__brand', { y: -10, opacity: 0 }, 0.3);
            tl.to('.menu__social', { duration: 0.3, x: -30, skewX: 5, opacity: 0 }, 0.4);
            tl.to('.menu__links', { duration: 0.3, x: 30, skewX: -5, opacity: 0 }, 0.4);
            tl.to('.menu__content', { display: 'none' }, 0.5);

            tl.to('.menu__container', { duration: 0.3, scale: 0 }, 0.8);
            tl.set('.menu__toggle', { backgroundColor: '#222222' })
            tl.set('.menu__icon', { attr: { src: menu } });
            tl.to('.menu__container', { display: 'none' });
            tl.set('body', { overflow: 'auto', onComplete: () => {setIsOpen(false); } } , 0.8);
            // set back to opacity 1
            tl.set('.menu__brand', { opacity: 1, skewX: 0, y: 0 }, 1);
            tl.set('.menu__social', { opacity: 1, skewX: 0, x: 0 }, 1);
            tl.set('.menu__links', { opacity: 1, skewX: 0, x: 0 }, 1);
        }
    }

    const onClick = () => {
        if(isOpen === false) {
            openMenu();
        } else {
            closeMenu();
        }
    }

    const scrollToPage = (cls) => {
        const domElement = document.querySelector(`.${cls}`);
        domElement.scrollIntoView(true);
        closeMenu();
    }

    return (
        <>
            <button onClick={onClick} className='menu__toggle p-5 md:mx-28 mt-8 mr-8 fixed top-0 right-0 z-50 bg-canvas-grey transition-colors ease-in hover:bg-black rounded-full '>
                <img className='menu__icon h-6 w-6 md:h-16 md:w-16' src={menu} alt="menu toggle" />
            </button>
            <div className='menu__container p-5 md:mx-28 mt-8 fixed top-5 right-5 md:top-8 md:right-8 z-40 bg-black rounded-full hidden overflow-hidden'></div>
            <div className='menu__content fixed top-0 left-0 z-40 h-full w-full hidden'>
                
                <div className='menu__brand brand p-5 md:px-28 mt-8'>
                    <img className='ml-2 w-auto h-16' src={brand} alt="Brand" />
                </div>

                <div className='flex flex-col h-full md:grid md:grid-cols-6 gap-3 px-5 md:mx-28'>

                    <div className='menu__social md:col-start-1 md:col-span-2'>
                        <div className='flex md:inline-flex md:flex-col gap-12 my-8'>
                            <a href="https://www.linkedin.com/in/ralphplacide-1/">
                                <i className="lab la-linkedin-in text-5xl text-secondary-grey transition-all duration-300 ease-linear bg-clip-text hover:text-transparent hover:bg-gradient-to-br hover:from-hot-pink hover:to-deep-orange hover:transform hover:-translate-y-1"></i>
                            </a>
                            <a href="https://github.com/ralphZero">
                                <i className="lab la-github text-5xl text-secondary-grey transition-all duration-300 ease-linear bg-clip-text hover:text-transparent hover:bg-gradient-to-br hover:from-hot-pink hover:to-deep-orange hover:transform hover:-translate-y-1"></i>
                            </a>
                            <a href="https://www.instagram.com/ralph.works/">
                                <i className="lab la-instagram text-5xl text-secondary-grey transition-all duration-300 ease-linear bg-clip-text hover:text-transparent hover:bg-gradient-to-br hover:from-hot-pink hover:to-deep-orange hover:transform hover:-translate-y-1"></i>
                            </a>
                            <a href="https://stackoverflow.com/users/13251130/tgi-rxlph">
                                <i className="lab la-stack-overflow text-5xl text-secondary-grey transition-all duration-300 ease-linear bg-clip-text hover:text-transparent hover:bg-gradient-to-br hover:from-hot-pink hover:to-deep-orange hover:transform hover:-translate-y-1"></i>
                            </a>
                        </div>
                    </div>

                    <div className='menu__links md:col-end-6 md:col-span-2'>
                        <ul className='flex gap-4 md:inline-flex flex-col justify-between'>
                            <li onClick={() => scrollToPage('home')}>
                                <span className='placide font-Poppins font-bold uppercase text-5xl transition-all duration-300 ease-linear hover:text-white hover:tracking-widest cursor-pointer'>home</span>
                            </li>
                            <li onClick={() => scrollToPage('about')}>
                                <span className='placide font-Poppins font-bold uppercase text-5xl transition-all duration-300 ease-linear hover:text-white hover:tracking-widest cursor-pointer'>about</span>
                            </li>
                            <li onClick={() => scrollToPage('work')}>
                                <span className='placide font-Poppins font-bold uppercase text-5xl transition-all duration-300 ease-linear hover:text-white hover:tracking-widest cursor-pointer'>projects</span>
                            </li>
                            <li onClick={() => scrollToPage('contact')}>
                                <span className='placide font-Poppins font-bold uppercase text-5xl transition-all duration-300 ease-linear hover:text-white hover:tracking-widest cursor-pointer'>contact</span>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </>
        
    );
}

export default Menutoggle;
