import React from 'react';

const Pagesubtitle = ({ text, id = '' }) => {
    return (
        <span id={id} className='page__title text-white text-lg md:text-2xl font-semibold my-12 self-start'>
            { text }
        </span>
    );
}

export default Pagesubtitle;
