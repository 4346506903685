import * as THREE from 'three';
//import { TweenLite } from 'gsap/TweenMax';

import InteractiveControls from './controls/InteractiveControls';
import Particles from './particles/Particles';

import ImageOne from '../../images/sample-03.png';
import ImageTwo from '../../images/sample-06.png';

//const glslify = require('glslify');
//import glslify from 'glslify';

export default class WebGLView {

	constructor(app) {
		this.app = app;

		this.samples = [
			ImageTwo,
			ImageOne
		];

		this.initThree();
		this.initParticles();
		this.initControls();

		if(window.innerWidth >= 1000) {
			this.goto(0);
		} else {
			this.goto(1);
		}
	}

	initThree() {
		// scene
		this.scene = new THREE.Scene();

		const canvas = document.querySelector('.hero__canvas');

		// camera
		this.camera = new THREE.PerspectiveCamera(50, canvas.clientWidth / window.innerHeight, 1, 1000);
		this.camera.position.z = 300;

		// renderer
        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });

        // clock
		this.clock = new THREE.Clock(true);
	}

	initControls() {
		this.interactive = new InteractiveControls(this.camera, this.renderer.domElement);
	}

	initParticles() {
		this.particles = new Particles(this);
		this.scene.add(this.particles.container);
	}

	// ---------------------------------------------------------------------------------------------
	// PUBLIC
	// ---------------------------------------------------------------------------------------------

	update() {
		const delta = this.clock.getDelta();

		if (this.particles) this.particles.update(delta);
	}

	draw() {
		this.renderer.render(this.scene, this.camera);
	}


	goto(index) {
		// init next
		if (this.currSample == null) this.particles.init(this.samples[index]);
		// hide curr then init next
		else {
			this.particles.hide(true).then(() => {
				this.particles.init(this.samples[index]);
			});
		}

		this.currSample = index;
	}

	// ---------------------------------------------------------------------------------------------
	// EVENT HANDLERS
	// ---------------------------------------------------------------------------------------------

	resize() {
		if (!this.renderer) return;
		const canvas = document.querySelector('.hero__canvas');
		
		this.camera.aspect = canvas.clientWidth / window.innerHeight;
		this.camera.updateProjectionMatrix();

		this.fovHeight = 2 * Math.tan((this.camera.fov * Math.PI) / 180 / 2) * this.camera.position.z;

		this.renderer.setSize(canvas.clientWidth, window.innerHeight);

		if (this.interactive) this.interactive.resize();
		if (this.particles) this.particles.resize();
	}
}
