import About from './sections/about';
import Contact from './sections/contact';
import Hero from './sections/hero';
import Works from './sections/works';
import Menutoggle from './components/Navbar/MenuToggle'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <div className="app static">
      <Menutoggle />
      <Hero />
      <About />
      <Works />
      <Contact />
      <ToastContainer position='bottom-center' autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick />
    </div>
  );
}

export default App;
