import React from 'react';
import brand from '../../assets/signature.svg';
import { Link } from 'react-router-dom';

const Brand = () => {
    return (
        <>
            <div className='brand p-5 md:px-28 mt-5 absolute  left-0 z-10'>
                <Link to='/'>
                    <img className='ml-2 w-auto h-16' src={brand} alt="Brand" />
                </Link>
            </div>
            <div className='absolute top-0 left-8 filter blur-2xl'>
                <div className='w-52 h-52 md:w-72 md:h-72 bg-gradient-to-br from-hot-pink to-deep-orange clip-triangle'></div>
            </div>
        </>

    )
}

export default Brand
