import React from 'react';

const Featuredproject = ({ project }) => {

    const tags = project.tags.map((tag, index) => {
        return (
            <span key={index} className='font-Montserrat text-xs uppercase text-white'>{ tag }</span>
        )
    });
    return (
        <div className="project__featured relative rounded-lg">
            <div className='relative pb-full bg-black rounded-lg'>
                <img className='absolute top-0 left-0 w-full h-full object-cover rounded-lg' src={ project.cover } height='353px' width='353px' alt="a" />
            </div>
            
            <div className='card absolute top-0 left-0 w-full h-full p-5 rounded-lg'>
                <div className="card__content flex flex-col justify-between h-full w-full rounded-lg z-10">
                    <div className='flex justify-between'>
                        <a href={project.code}>
                            <i className="las la-code text-3xl text-secondary-grey"></i>
                        </a>
                        <a href={project.url}>
                            <i className="las la-external-link-alt text-3xl text-secondary-grey"></i>
                        </a>
                    </div>
                    <div className='inline-block'>
                        <h6 className='font-Montserrat font-semibold text-xs capitalize about__link'>featured project</h6>
                        <h1 className='font-Poppins font-semibold text-2xl text-white'>{ project.title }</h1>
                        <h3 className='font-Poppins text-lg text-gray-300'>{ project.description }</h3>
                    </div>
                    <div className='flex gap-4'>
                        { tags }
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Featuredproject;
