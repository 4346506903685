import React, { useState } from 'react';
import { db } from '../../firebase';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { toast } from 'react-toastify'

const Contactform = () => {

    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    // errors
    const [emailError, setEmailError] = useState('');
    const [messageError, setMessageError] = useState('');

    const validateEmail = (mail) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
            return (true);
        } else {
            return (false);
        }
    }

    const validateForm = (e) => {
        e.preventDefault();
        if(loading) return;

        setEmailError('');
        setMessageError('');
        
        if(!validateEmail(email)) {
            setEmailError('');
            return;
        } else {
            // email seems ok
            setLoading(true);
            addDoc(collection(db, 'contact'), {
                email: encodeURIComponent(email),
                subject: encodeURIComponent(subject),
                message: encodeURIComponent(message),
                createdAt: Timestamp.fromDate(new Date()).toDate(),
            }).then((val) => {
                setEmail('');
                setSubject('');
                setMessage('');
                setLoading(false);
                toast.success('message sent!', {
                    theme: 'dark',
                });
            }).catch((err) => {
                setEmail('');
                setSubject('');
                setMessage('');
                setLoading(false);
                toast.error('An error has occurred!', {
                    theme: 'dark',
                });
            });
        }
    }

    return (
        <form onSubmit={validateForm} className='form flex w-full md:w-8/12 flex-col items-center gap-6 md:px-28' action="">
            <div className='form__email w-full'>
                <input required onChange={(e) => setEmail(e.target.value)} className='w-full h-10 rounded-lg bg-primary-darkgrey placeholder-secondary-grey font-Poppins p-3 text-white focus:outline-none focus:ring focus:ring-hot-pink' type="email" placeholder='email address *' name="f_email" value={email}/>
                <small className={emailError ? 'block' : 'hidden'}>{emailError}</small>
            </div>
            <div className='form__subject w-full'>
                <input onChange={(e) => setSubject(e.target.value)} className='w-full h-10 rounded-lg bg-primary-darkgrey placeholder-secondary-grey font-Poppins p-3 text-white focus:outline-none focus:ring focus:ring-hot-pink' type="text" placeholder='subject' name="f_subject" value={subject} />
            </div>
            <div className='form__message w-full'>
                <textarea required onChange={(e) => setMessage(e.target.value)} className='w-full h-28 rounded-lg bg-primary-darkgrey placeholder-secondary-grey font-Poppins p-3 text-white focus:outline-none focus:ring focus:ring-hot-pink' placeholder='message *' name="f_message" value={message}></textarea>
                <small className={messageError ? 'block' : 'hidden'}>{messageError}</small>
            </div>
            
            <button className='form__button bg-gradient-to-br p-px from-hot-pink to-deep-orange rounded-lg my-4'>
                <div className='font-semibold text-lg text-white bg-primary-black rounded-lg py-2 px-12 transition-colors ease-in-out hover:bg-transparent'>
                    {loading ? <div className='animate-spin rounded-full h-7 w-7 border-b-2 border-white'></div> : <span>let's talk</span>}
                </div>
            </button>
        </form>
    );
}

export default Contactform;