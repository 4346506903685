export class Project {

    constructor({title, description, url, code, cover, tags}) {
        this.title = title;
        this.description = description;
        this.url = url;
        this.code = code;
        this.tags = tags;
        this.cover = cover;
    }

    static fromSnapshot(snapshot) {
        return new Project({
            title: snapshot['title'],
            description: snapshot['description'],
            url: snapshot['url'],
            code: snapshot['code'],
            tags: snapshot['tags'],
            cover: snapshot['cover'],
        });
    }

}