import React from 'react';

const Loaderproject = () => {
    return (
        <div className='project__featured_ relative'>
            <div className='relative pb-full bg-primary-darkgrey rounded-lg animate-pulse'>
                <div className='absolute top-0 left-0 w-full h-full object-cover rounded-lg'>
                    <div className='h-96 w-96'></div>
                </div>
            </div>
        </div>
    );
}

export default Loaderproject;
