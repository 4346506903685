import React, { useEffect } from 'react';
import { text } from '../assets/about.json';
import hero from '../assets/me_hero.webp';
import PageTitle from '../components/PageTitle';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const About = () => {
    gsap.registerPlugin(ScrollTrigger);
    
    const createMarkup = () => {
        return {__html: text}
    };

    useEffect(() => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.about',
                start: 'top 60%',
                end: '20% 30%',
                scrub: 1,
            }
        });
        tl.from('.about', { backgroundColor: '#222222' });
        tl.from('#about__title', { opacity: 0, y: 40 });
        tl.from('.about__desc', { duration: 1, opacity: 0, x: -40 });
        tl.from('.about__photo', { duration: 1, opacity: 0, x: 40 });
    }, []);

    return (
        <div className='about p-5 md:p-28 bg-primary-black flex flex-col items-center'>
            <PageTitle id='about__title' text='who am i ?' />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-16 items-center my-12 md:my-24">
                <div className='about__desc col-span-2 font-Poppins text-primary-grey text-base md:text-lg text-justify' dangerouslySetInnerHTML={createMarkup()}></div>
                <div className='about__photo relative pb-full md:-left-5 group'>
                    <div className='absolute top-0 left-0 bg-gradient-to-br from-hot-pink to-deep-orange w-full h-full z-10 rounded-lg opacity-60 transition-all ease-linear group-hover:opacity-0 group-hover:bg-transparent'></div>
                    <img className='absolute top-0 left-0 about__hero w-full h-full  object-cover rounded-lg filter grayscale' src={ hero } height='320px' width='320px' alt="Me" />
                    <div className='absolute top-5 left-5 w-full h-full bg-gradient-to-br from-hot-pink to-deep-orange rounded-lg p-1 transition-all ease-in group-hover:top-2 group-hover:left-2'>
                        <div className='w-full h-full bg-primary-black rounded-lg'></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
