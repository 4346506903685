import React, { Component } from 'react';
import WebGLView from '../effect/scripts/webgl/WebGLView';
import Brand from '../components/Navbar/Brand';

class Hero extends Component {

    componentDidMount() {
        this.init();
    }

    init() {
        this.initWebGL();
        this.addListeners();
        this.animate();
        this.resize();
    }

    initWebGL() {
        this.webgl = new WebGLView(this);
        document.querySelector('.hero__canvas').appendChild(this.webgl.renderer.domElement);
    }

    addListeners() {
        this.handlerAnimate = this.animate.bind(this);

        window.addEventListener('resize', this.resize.bind(this));

        window.addEventListener('resize', this.changeSample.bind(this));
    }

    changeSample(e) {
        if (window.innerWidth >= 1000) {
            if (this.webgl.currSample !== 0) this.webgl.goto(0);
        } else {
            if (this.webgl.currSample !== 1) this.webgl.goto(1);
        }
    }

    animate() {
        this.update();
        this.draw();

        this.raf = requestAnimationFrame(this.handlerAnimate);
    }

    // public methods
    update() {
        if (this.webgl) this.webgl.update();
    }

    draw() {
        if (this.webgl) this.webgl.draw();
    }

    // event handlers
    resize() {
        if (this.webgl) this.webgl.resize();
    }

    scrollToPage(cls) {
        const domElement = document.querySelector(`.${cls}`);
        domElement.scrollIntoView(true);
    }

    

    render() {
        const resumeLink = 'https://firebasestorage.googleapis.com/v0/b/ralph-placide.appspot.com/o/placide_resume_2022.pdf?alt=media&token=0f40467d-5cf6-419d-9f4c-e40fd971313b';
        
        return (
            <div className='home hero__canvas relative'>
                <Brand />
                <div className='absolute bottom-12 md:top-1/2 md:transform md:-translate-y-1/2 p-5 md:pl-28'>
                    <div className='font-bold text-white text-7xl transform translate-y-3 md:text-8xl'>Ralph</div>
                    <div className='font-bold text-white text-7xl transform translate-y-3 md:text-8xl'>Sébastien</div>
                    <div className='placide font-bold text-7xl md:text-8xl relative left-px'>Placide.</div>
                    <div className="h-3 w-32 bg-gradient-to-br from-hot-pink to-deep-orange relative left-2"></div>
                    <div className='font-Montserrat font-semibold text-2xl text-gray-100 ml-1 my-2 uppercase'>Software engineer</div>
                    <button onClick={() => window.open(resumeLink, "_blank")} className='call-to-action bg-gradient-to-br p-px from-hot-pink to-deep-orange rounded-lg mt-4 ml-1'>
                        <div className='font-semibold text-lg text-white bg-canvas-grey rounded-lg py-2 px-12 transition-colors ease-in-out hover:bg-transparent'>my resume</div>
                    </button>
                </div>
            </div>
        )
    }
}

export default Hero
