import React from 'react';

const Otherproject = ( { project }) => {

    const tags = project.tags.map((tag, index) => {
        return (
            <span key={index} className='font-Montserrat text-xs uppercase text-white'>{ tag }</span>
        )
    });

    return (
        <div className="project__other group relative w-full h-full rounded-lg">
            <div className="relative pb-full rounded-lg">
                <div className="absolue top-0 left-0 w-full h-full"></div>
            </div>
            <div className='absolute top-0 left-0 w-full h-full rounded-lg p-px transition-all duration-300 ease-out bg-transparent from-hot-pink to-deep-orange group-hover:bg-gradient-to-br'>
                <div className="flex flex-col justify-between h-full w-full bg-primary-darkgrey rounded-lg z-10 p-5 transition-colors">
                    <div className='flex justify-between'>
                        <a href={project.code}>
                            <i className="las la-code text-3xl text-secondary-grey"></i>
                        </a>
                        <a href={project.url}>
                            <i className="las la-external-link-alt text-3xl text-secondary-grey"></i>
                        </a>
                    </div>
                    <div className='block'>
                        <h1 className='font-Poppins font-semibold text-2xl text-white'>{ project.title }</h1>
                        <h3 className='font-Poppins text-lg text-gray-300'>{ project.description }</h3>
                    </div>
                    <div className='flex gap-4'>
                        { tags }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Otherproject;
