import React, {  useEffect, useState } from 'react';
import Pagetitle from '../components/PageTitle';
import Featuredproject from '../components/Works/FeaturedProject';
import Otherproject from '../components/Works/OtherProject';
import Pagesubtitle from '../components/PageSubtitle';
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { getDocs, collection, query } from 'firebase/firestore';
import { db } from '../firebase';
import { Project } from '../models/Project';
import Loaderproject from '../components/Works/LoaderProject';

const Works = () => {
    gsap.registerPlugin(ScrollTrigger);

    const [featuredProjects, setFeaturedProjects] = useState([]);
    const [otherProjects, setOtherProjects] = useState([]);

    useEffect(() => {
        var followY = 0;
        var ease = 0.15;

        gsap.set(".project__featured, .project__other", { transformOrigin: "center left" });

        gsap.ticker.add(function() {
        followY += (window.scrollY - followY) * ease;

        var dy = (window.scrollY - followY) / 20;
        dy = Math.min(Math.max(dy, -15), 15);
        gsap.set(".project__featured, .project__other", { skewY: dy });
        });
    }, []);

    useEffect(() => {
        const qry = query(collection(db, 'project'));
        getDocs(qry).then((snapshot) => {
            
            if(!snapshot.empty) {
                // if has data
                snapshot.forEach((doc) => {
                    if(doc.data()['featured'] === true) {
                        const project = Project.fromSnapshot(doc.data());
                        setFeaturedProjects(prev => [...prev, project]);
                    } else {
                        const project = Project.fromSnapshot(doc.data());
                        setOtherProjects(prev => [...prev, project]);
                    }
                });
                animateCards();
            } else {
                // if not
                
            }
        })
        .catch((err) => {
            //error
            // console.log('error: ' + err.message);
        });

    }, []);

    const animateCards = () => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.work',
                start: 'top 80%',
                end: '20% 50%',
                scrub: 1,
            }
        });
        tl.from('#work__featured_title', { opacity: 0, y: 70 });
        tl.from('.project__featured', {
            opacity: 0,
            y: 90,
            scrollTrigger: {
                trigger: '.project__featured',
                start: 'top 70%',
                end: '20% 50%',
                scrub: 1,
            }
        });
        tl.from('#work__other_title', {
            opacity: 0,
            y: 70,
            scrollTrigger: {
                trigger: '#work__other_title',
                start: '40% 70%',
                end: '20% 50%',
                scrub: 1,
            }
        });
        tl.from('.project__other', {
            opacity: 0,
            y: 90,
            scrollTrigger: {
                trigger: '.project__other',
                start: 'top 70%',
                end: '20% 50%',
                scrub: 1,
            }
        });
    }


    const loaders = (amount) => {
        const array = [];
        for (let i = 0; i < amount; i++) {
            array.push((
                <Loaderproject />
            ));
        }
        return array;
    };
    

    const featured = featuredProjects.map((project, index) => {
        return (
            <Featuredproject key={index} project={project} />
        );
    });

    const others = otherProjects.map((project, index) => {
        return (
            <Otherproject key={index} project={project} />
        )
    });

    

    return (
        <div className='work flex flex-col items-center p-5 md:px-28 w-full'>
            <Pagetitle id='work__featured_title' text="stuff i've worked on"/>
            <div className='grid grid-cols-1 md:grid-cols-3 mt-24 gap-6 w-full'>
                { featuredProjects.length > 0 ? featured : loaders(3) }
            </div>
            <br />
            <Pagesubtitle id='work__other_title' text='other projects' />
            <div className='grid grid-cols-1 md:grid-cols-3 gap-6 w-full'>
                { otherProjects.length > 0 ? others : loaders(3) }
            </div>
            <button onClick={() => window.open("https://github.com/ralphZero?tab=repositories", "_blank")} className='call-to-action bg-gradient-to-br p-px from-hot-pink to-deep-orange rounded-lg mt-5 ml-1'>
                <div className='font-semibold text-lg text-white bg-primary-black rounded-lg py-2 px-12 transition-colors ease-in-out hover:bg-transparent'>my repos</div>
            </button>
        </div>
    );
}

export default Works;