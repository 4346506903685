import React from 'react';
import Brand from '../components/Navbar/Brand';

const DevDiveLinks = () => {
  return (
    <div className='flex justify-center'>
      <Brand />
      <div className='inline-flex flex-col justify-center items-center pt-36 gap-8'>
        <div className='text-white text-4xl'>Workshop ⚡️</div>
        <div className='text-white underline hover:text-hot-pink hover:no-underline text-2xl'>
          <a target='_blank' rel='noreferrer' href="https://zapp.run/edit/wine-collection-starter-zq5406fkq550">Zapp.run</a>
        </div>
        <div className='text-white text-4xl'>Flutter useful resources</div>
        <div className='text-3xl'>📚</div>
        <div className='text-white underline hover:text-hot-pink hover:no-underline text-2xl'>
          <a target='_blank' rel='noreferrer' href="https://docs.flutter.dev/get-started/install">Flutter - Get Started</a>
        </div>
        <div className='text-white underline hover:text-hot-pink hover:no-underline text-2xl'>
          <a target='_blank' rel='noreferrer' href="https://docs.flutter.dev/get-started/flutter-for/web-devs">Flutter for web devs</a>
        </div>
        <div className='text-white underline hover:text-hot-pink hover:no-underline text-2xl'>
          <a target='_blank' rel='noreferrer' href="https://docs.flutter.dev/cookbook">Flutter Cookbook</a>
        </div>
        <div className='text-white underline hover:text-hot-pink hover:no-underline text-2xl'>
          <a target='_blank' rel='noreferrer' href="https://medium.com/@flutterdynasty">Curated Flutter Medium Articles</a>
        </div>
        <div className='text-3xl'>📽️</div>
        <div className='text-white underline hover:text-hot-pink hover:no-underline text-2xl'>
          <a target='_blank' rel='noreferrer' href="https://www.youtube.com/@flutterdev/playlists">Flutter youtube channel</a>
        </div>
        <div className='text-white underline hover:text-hot-pink hover:no-underline text-2xl text-center'>
          <a target='_blank' rel='noreferrer' href="https://www.youtube.com/@NetNinja/playlists">The Net Ninja - (Has Dart & Flutter contents)</a>
        </div>
      </div>
    </div>
  )
}

export default DevDiveLinks